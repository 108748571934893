@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Michroma&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Exa:wght@100..900&display=swap');


:root {
  --font-family-secondary: 'Lexend Exa', sans-serif;
  --font-family: 'Michroma', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  /* --color-bg: #040C18;    */
  /* --color-bg: #020100; */
  --color-bg: linear-gradient(90deg, #000000 0%, #00000000 100%);
  --color-bg-rl: linear-gradient(180deg, #000000b6 50%, #00000000 100%);
  /* --color-footer : #031B34; */
  /* --color-footer : #1a1a1a; */
  --color-footer : linear-gradient(90deg, #343434 0%, #000000 100%);
  /* --color-footer : linear-gradient(180deg, #001F3E 0%, #001226 100%); */
  /* --color-gradient-new: linear-gradient(90deg, #001F3E 0%, #001226 100%); */
  --color-gradient-new: linear-gradient(90deg, #343434 0%, #000000 100%);
  --color-hero: #042c54;
  /* --color-text: #81AFDD; */
  --color-text: #ffffff;
  /* --color-subtext: #FF8A71; */
  --color-subtext: #e4020f;
  /* --color-subtext: #f7f28d; */

  /* #f7f28d */

  /* --color-buttons-bg-primary: #1b057c; */
  --color-buttons-bg-primary: #FB0016;
}

@tailwind base;
@tailwind components;
@tailwind utilities;