
#overlay_firstsection {
    /* display: none; */
    background: linear-gradient(0deg, #000000 0%, #00000000 100%);
    position: absolute; /* Sit on top of the page content */
    /*display: none; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 48vw; /* Full height (cover the whole page) */
    /* margin-top: -4.8vw; */
    /* left: 0;
    right: 0;
    bottom: 0; */
    /* background-color: rgba(0,0,0,0.5); Black background with opacity */
    z-index: 1; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
  }

.main__firstsection {
    /* min-height: 709px; */
    /* height: 100vh; */
    height: 48vw;
    /* width: 1441px; */
    /* display: flex; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.main__firstsection__bg {
    background-image: url("../../data/bg_2.png"); 
    /* background-color: #000000; */
    /* height: 100%; You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    /* margin-top: 2px; */
    overflow: hidden;
}

.main__firstsection__wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    /* margin-top: 23vw; */
    /* margin-top: -4.8vw; */
    /* margin-left: -3.52vw; */
}

.main__firstsection__abstract {
    display: flex;
    /*justify-content: flex-start;
    align-items: center; */
    /* flex: 1; */
    /* width: 1007px; */
    width: 66vw;
    /* height: 838px; */
    /* height: 58.5vw; */
    /* height: 58.5vw; */
    position: absolute;
    /* margin-top: -82px; */
    
    /* margin-left: -61px; */
    
    overflow: hidden;
}

.main__firstsection__img {
    display: flex;
    /* justify-content: flex-start; 
    align-items: center; */
    /* flex: 1; */
    /* width: 1280px; */
    /* width: 68.6vw; */
    width: 44.8vw;
    /* height: 720px; */
    /* height: 50.1vw; */
    position: relative;
    /* margin-top: 24px; */
    margin-top: 1.8vw;
    /* margin-left: -294px; */
    margin-left: 4vw;
}
@keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  /* Apply floating effect on hover */
  .main__firstsection__img_float img:hover {
    animation: float 2s ease-in-out infinite;
  }
  .main__firstsection__img_float img {
    animation: float 2s ease-in-out infinite;
  }


.main__firstsection__textbox {
    /* display: flex; */
    /* justify-content: flex-start; */
    /* align-items: center; */
    /* flex-direction: column; */
    /* margin-top: 233px; */
    margin-top: -5.5vw;
    margin-left: 65.5vw;
    /* position: relative; */
    /* width: 378px; */
    width: 28vw;
    color: var(--color-text);
}

.main__firstsection__textbox img {
    /* width: 94px; */
    /* height: 21px; */
    height: 1.35vw;
    /* margin-bottom: 1vw; */
}

.main__firstsection__textbox h1 {
    /* margin-top: 20px; */
    font-family: var(--font-family);
    font-size: 2.2vw;
    /* color: var(--color-text); */
}

.main__firstsection__textbox p {
    padding-top: 20px;
    font-family: var(--font-family-secondary);
    font-size: 1vw;
    font-weight: 200;
    /* color: var(--color-text); */
}

@media screen and (max-width: 550px) {

    .main__firstsection {
        height: 86vw;
    }

    .main__firstsection__bg {
        background-position-x: 95%;
        /* background-position-y: 25%; */
    }

    .main__firstsection__abstract {
        width: 92vw;
    }

    .main__firstsection__img {
        width: 66vw;
    }

    .main__firstsection__textbox {
        display: None;
    }

    .main__firstsection__wrapper {
        margin-left: 4.8vw;
    }

}