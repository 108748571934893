::placeholder {
    text-align: center; 
 }

 #overlay {
    /* display: none; */
    /* background: linear-gradient(0deg, #000000 0%, #00000000 50%); */
    position: absolute; /* Sit on top of the page content */
    /*display: none; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 57vw; /* Full height (cover the whole page) */
    margin-top: -4.8vw;
    /* left: 0;
    right: 0;
    bottom: 0; */
    /* background-color: rgba(0,0,0,0.5); Black background with opacity */
    z-index: 1; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
  }

  .mobile-only {
    display: none;
  }

.main__hero {
    display: flex;
    flex-direction: row;
    /* height: 100%; vh; */
    /* height: 57vw; */
    height: 54vw;
    /* margin-top: 21rem; */
    /* bottom: 2rem; */
    /* margin-top: -4.8vw; */
    margin-top: -2vw;
    flex-grow: 1;
    /* position: absolute; */
    /* min-height: 100%; */
    align-items: center;
    /* justify-content: center; */
    z-index: 0;
    overflow: hidden;
}

.main__hero__bg {
    /* background-image: url("../../data/bg.png");  */
    background-image: url("../../data/bg_hero_hd.png"); 
    /* background-color: #000000; */
    /* height: 100%; You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    /* margin-top: 2px; */
    overflow: hidden;
  }

.main__hero-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    /* margin-right: 5rem; */
    max-width: 54vw;
    /* margin-bottom: 28rem; */
    /* bottom: 2rem; */
    /* position: relative; */
    /* height: 29rem; */
    /* height: 100%; */
    z-index: 2;

    
}

.main__hero-content h1 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 2.8vw;
    /* line-height: 4vw; */
    /* letter-spacing: -0.04em; */
    max-width: 35vw;
    color: var(--color-text);
}

.main__hero-content p {
    font-family: var(--font-family);
    font-weight: 100;
    font-size: 1.1vw;
    /* line-height: 1.2vw; */
    color: var(--color-text);

    margin-top: .2rem;
    max-width: 24vw;

}

.main__hero-content__interaction {
    width: 100%;
    /* margin: 2rem 0 1rem; */
    /* margin-top: 7.7vh; */
    margin-top: 4.25vw;
    /* padding-bottom: 1rem; */
    height: 10vw;

    justify-content: center;
    display: flex;
    flex-direction: column;
    /* margin-bottom: 1.4vw; */
}

.main__hero-content__interaction input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.main__hero-content__interaction p,
.main__hero-content__interaction button {
    /* flex: 0.63; */
    width: 18.2vw;
    /* min-height: 65px; */
    /* font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-buttons-bg-primary);
    border: 2px solid var(--color-buttons-bg-primary);;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;
    opacity: 90%;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px; */
    
}

.main__hero-content__interaction-input {
    margin-top: 5rem;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.main__hero-content__interaction-input button{
    margin-top: 1rem;
    /* width: 5vw; */
}

.main__hero-content__interaction-socials {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    max-width: 7.98vw;
    margin-top: 1.4vw;
    margin-left: 0.4vw;
    /* min-height: 35vw; */
    /* min-height: 1.2vw;
    min-width: 5.64vw; */
    margin-bottom: 4.6vw;
}

.main__hero-content__interaction-socials-x img {
    height: 1.3vw;
    width: 1.77vw;
    /* flex-grow: 1; */
    display: none;

}

.main__hero-content__interaction-socials-d img{
    height: 1.2vw;
    width: 1.97vw;
    /* margin-top: -0.1vw; */
    display: none;
}

.main__hero-content__interaction-socials-ig img{
    height: 2.1vw;
    width: 1.87vw;
    margin-top: -0.19vw;
    display: none;
}

.main__hero-image__socials {
    /* flex: 1; */
    /* display: flex;
    justify-content: center;
    align-items: flex-end; */
    position: absolute;
    /* right: 6rem; */
    right: 0;
    /* left: 5rem; */
    /* bottom: 37rem; */
    top: 16rem;
    /* top: 12rem; */
    width: 55px;
    height: 55px;
    
    
}

.main__hero-image__socials img {
    width: 100%;
    height: 100%;
    opacity: 80%;
    
}

.main__hero-image__socials_2 {
    /* flex: 1; */
    /* display: flex;
    justify-content: center;
    align-items: flex-end; */
    position: absolute;
    /* right: 6rem; */
    right: 0;
    /* left: 5rem; */
    top: 20rem;
    /* top: 12rem; */
    width: 55px;
    height: 55px;
    
}

.main__hero-image__socials_2 img {
    width: 100%;
    height: 100%;
    opacity: 80%;
    
}


.main__hero-image {
    flex: 1;
    /* display: flex;
    justify-content: center;
    align-items: flex-end; */
    position: absolute;
    right: 0rem;
    top: 0rem;
    
}

.main__hero-image img {
    width: 25%;
    height: 25%;
    
}

/* Style the video: 100% width and height to cover the entire window */

  
/*
  .video__bg {
    height: 300px;
    width: 300px;
    position: relative;
  }
  
  .video__bg video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index:10;
  }
  
  
  .video__bg .caption {
  z-index: 0;
  position: relative;
  text-align: center;
  color: #dc0000;
  padding: 10px;
  }

   */

@media screen and (max-width: 1250px) {
    .main__hero {
        /* flex-direction: row; */
        /* margin: 0rem; */
        /* align-items: flex-end; */
        /* justify-content: flex-end; */
        /* height: 86vh; */
    }

    .main__hero-content {
        /* margin: 5rem 0 3rem; */
        /* min-width: 100%; */
        /* flex: 1; */
    }

    /* .main__hero-content__interaction {
        min-height: 13rem;
    } */

    .main__hero-content__interaction,
    .main__hero-content__interaction button {
        /* width: 100%; */
        /* margin: 0; */
    }

    .main__hero-image__socials {
        /* bottom: 37rem; */
    }
}

@media screen and (max-width: 650px) {

    .main__hero-content {
        /* margin: 6rem 0 3rem; */
        /* min-width: 100%; */
    }

    .main__hero-content h1 {
        /* font-size: 48px; */
        /* line-height: 60px; */
    }

    .main__hero-content p {
        /* font-size: 16px; */
        /* line-height: 24px; */
    }

    .main__hero-content__interaction input,
    .main__hero-content__interaction button {
        /* font-size: 16px; */
        /* line-height: 24px; */
    }

    
}

@media screen and (max-width: 550px) {
    .mobile-only {
        display: flex;
      }

      .desktop-only {
        display: none;
      }

    .main__hero {
        height: 82.5vw;
        margin-top: -0.8vw;
        /* align-items: flex-end; */

        /* top:2vw; */
        /* width: 110vw; */
        
    }

    .main__hero-content {
        justify-content: flex-end;
        align-items: center;
        /* flex-direction: column; */
        margin: 6rem 0 3rem;
        /* margin-top: 50rem; */
        /* max-width: 54vw; */
        min-width: 100%;
    }

    .main__hero-content h1 {
    
        display: none;
    }

    .main__hero-content h1 {
        display: none;
    }

    .main__hero-content p {
    
        display: none;
    }


    .main__hero-content__interaction {
        /* min-height: 10rem; */
        /* margin-bottom: 5.3rem; */
        width: 77.8vw;
        margin-bottom: -6.7vw;
        margin-bottom: -14.8vw;
        /* padding-bottom: 1rem; */
        height: 0vw;
    }

    

    .main__hero-content__interaction button {
        
    }

    .main__hero-content__interaction input,
    .main__hero-content__interaction button {
        /* font-size: 17.5px;
        line-height: 16px; */
        margin-top: -1.9vw;
        width: 100%;

    }

    .main__hero-content__interaction-socials {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: row;
        max-width: 100vw;
        margin-top: 2.7vw;
        margin-left: 0.4vw;
        /* min-height: 35vw; */
        /* min-height: 1.2vw;
        min-width: 5.64vw; */
        /* margin: 0 1rem; */
    }
    
    .main__hero-content__interaction-socials-x img {
        height: 5.2vw;
        width: 7.08vw;
        /* flex-grow: 1; */
    
    }
    
    .main__hero-content__interaction-socials-d img{
        height: 4.8vw;
        width: 7.88vw;
        /* margin-top: -0.1vw; */
        margin-right: 4vw;
        margin-left: 4vw;
    }
    
    .main__hero-content__interaction-socials-ig img{
        height: 8.4vw;
        width: 7.48vw;
        margin-top: -0.76vw;
    }

    #overlay {
        height: 86.5vw;
        background: linear-gradient(0deg, #000000 0%, #00000000 50%);
      }

    

    
}