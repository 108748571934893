.main__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0rem 6rem;
    /* z-index: 1001; */
    z-index: 1990;
    /* background-color: var(--color-footer); */
    /* background: linear-gradient(90deg, #001F3E 0%, #001226 100%); */
    background: var(--color-gradient-new);
    position: relative;
}

.main__navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.main__navbar-links_logo {
    /* margin-right: 2rem; */
    /* display: flex; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* flex: 1; */
    /* width: 265px; */
    height: 4.8vw;
    /* width: 320px;
    height: 128px; */
}

.main__navbar-links_logo img {
    width: 10.2vw;
    /* width: 265px; */
    /* height: 145px; */
}

.main__navbar-links_container {
    display: flex;
    /* width: 100%; */
    flex-direction: row;
    justify-content: center;
}

.main__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.main__navbar-links_container p,
.main__navbar-sign p,
.main__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family-secondary);
    font-weight: 300;
    font-size: 0.93vw;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 2.9vw;
    cursor: pointer;
}

.main__navbar-sign p {
    margin: 0 0.2vw;
}

.main__navbar-sign button,
.main__navbar-menu_container button {
    /* padding: 1rem 1rem; */
    color: #fff;
    /* background: var(--color-buttons-bg-primary); */
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    /* border-radius: 5px;
    border: 0;
    outline: none; */
    cursor: pointer;
    width: 10.2vw;
    /* flex: 1; */
    /* height: 145px; */
    /* visibility: hidden; */
}

.main__navbar-menu {
    margin-left: 1rem;
    /* font-size: 5vw; */
    display: none;
    position: relative;
}

.main__navbar-menu svg {
    cursor: pointer;
}

.main__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;
    background: var(--color-footer);
    /* background-color:  */
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0,0,0,0.2);
    z-index: 2000;
}

.main__navbar-menu_container p {
    margin: 1rem 0;
    font-size: 5vw;
}

.main__navbar-menu_container-links-sign {
    /* display: none; */
}

.main__navbar-socials-x img {
    height: 1.3vw;
    width: 1.77vw;
    /* flex-grow: 1; */
    /* display: none; */

}

@media screen and (max-width: 1250px) {
    .main__navbar-links_container,
    .main__navbar-menu_container button {
        display: none;
        /* width: 10vw; */
    }

    .main__navbar-menu {
        display:flex;
    }

    .main__navbar-sign {
        display: none;
    }

    .main__navbar-menu img {
        width: 3.45vw;
    }

    .main__navbar {
        /* padding: 0 1.5rem; */
        /* padding: 0 6.7vw; */
    }

    

    .main__navbar-menu_container {
        /* top: 20px; */
    }

    /* .main__navbar-menu_container-links-sign {
        display: block;
    } */

    .main__navbar-links_logo {
        /* margin-right: 2rem; */
        /* display: flex; */
        /* display: flex;
        justify-content: flex-start;
        align-items: center; */
        /* flex: 1; */
        /* width: 265px; */
        /* height: 51px; */
        height: 5.18vw;
        /* width: 320px;
        height: 128px; */
    }
    
    .main__navbar-links_logo img {
        /* width: 10.2vw; */
        /* width: 105px; */
        width: 12vw;
        /* height: 145px; */
    }
    
}

@media screen and (max-width: 700px) {
    .main__navbar {
        padding: 0.55rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .main__navbar {
        /* padding: 0 1.5rem; */
        padding: 0 6.7vw;
    }

    

    .main__navbar-menu_container {
        top: 20px;
    }

    /* .main__navbar-menu_container-links-sign {
        display: block;
    } */

    .main__navbar-links_logo {
        /* margin-right: 2rem; */
        /* display: flex; */
        /* display: flex;
        justify-content: flex-start;
        align-items: center; */
        /* flex: 1; */
        /* width: 265px; */
        /* height: 51px; */
        height: 14.18vw;
        /* width: 320px;
        height: 128px; */
    }
    
    .main__navbar-links_logo img {
        /* width: 10.2vw; */
        /* width: 105px; */
        width: 29.31vw;
        /* height: 145px; */
    }

    .main__navbar-menu img {
        /* width: 23px; */
        width: 6.45vw;
    }
}