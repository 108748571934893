.main__scoretable {
    /* height: 709px; */
    /* height: 48vw; */
    /* width: 1440px; */
    /* background-color: #ffffff; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* overflow: hidden; */
    /* max-width: 100%; */
}

.main__scoretable__bg {
    background-image: url("../../data/bg_4.png"); 
    /* background-color: #000000; */
    /* height: 100%; You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    /* margin-top: 2px; */
    overflow: hidden;
    /* width: 100%; */
}

.main__scoretable__wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    /* margin-top: 23vw; */
    /* margin-top: -0.5vw; */
    /* margin-left: -2vw; */
    overflow: hidden;
    /* max-width: 100%;
    width: 100%; */
    height: 48vw;
}

.main__scoretable__abstract {
    display: flex;
    /* justify-content: flex-start;
    align-items: center; */
    /* flex: 1; */
    /* width: 1203px; */
    /* max-width: none; */
    /* width: 79vw; */
    /* width: 90vw; */
    /* height: 1000px; */
    /* height: 70.5vw; */
    position: absolute;
    /* margin-top: -145px;
    margin-left: -70px; */
    overflow: hidden; 
}

.main__scoretable__img {
    display: flex;
    /* justify-content: flex-start;
    align-items: center; */
    /* flex: 1; */
    /* width: 1046px; */
    max-width: none;
    /* width: 42.3vw; */
    width: 35vw;
    /* height: 588px; */
    /* height: 41vw; */
    position: relative;
    /* margin-top: 47px; */
    /* margin-top: -2.5vw; */
    margin-top: -3.3vw;
    /* margin-left: 16.2vw; */
    margin-left: 20.2vw;
    /* margin-left: -31px; */
    /* margin-left: 4.2vw; */
    /* display: none; */
    overflow: hidden;
}
/* Define keyframes for floating effect */
@keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  /* Apply floating effect on hover */
  .main__scoretable__img img:hover {
    animation: float 2s ease-in-out infinite;
  }
  .main__scoretable__img img {
    animation: float 2s ease-in-out infinite;
  }


.main__scoretable__textbox {
    /* display: flex; */
    /* justify-content: flex-start; */
    /* align-items: center; */
    /* flex-direction: column; */
    /* margin-top: -4vw;
    margin-left: 65.5vw; */
    /* position: absolute; */
    /* width: 28vw; */
    color: var(--color-text);
}

.main__scoretable__textbox img {
    /* width: 94px;
    height: 21px; */
    margin-top: 2vw;
    height: 4vw;
}

.main__scoretable__textbox {
    z-index: 1;
}

.main__scoretable__textbox-accent img {
    /* width: 94px;
    height: 21px; */
    height: 1.35vw;
}

.main__scoretable__textbox h1 {
    /* margin-top: 20px; */
    font-family: var(--font-family);
    font-size: 2.2vw;
}

.main__scoretable__textbox p {
    padding-top: 20px;
    font-family: var(--font-family-secondary);
    font-size: 1vw;
    font-weight: 200;
}

@media screen and (max-width: 550px) {
    .main__scoretable {
        height: 86vw;
    }

    .main__scoretable__bg {
        /* background-position-x: 95%; */
        /* background-position-y: 10%; */
    }

    .main__scoretable__abstract {
        width: 126vw;
    }

    .main__scoretable__img {
        width: 56vw;
        margin-top: -5vw;
        margin-left: 32vw;
    }

    .main__scoretable__textbox {
        display: None;
    }

    .main__scoretable__wrapper {
        height: 86vw;
        margin-left: -4vw;
    }

}