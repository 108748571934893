.main__footer {
    /* background: var(--color-footer); */
    /* opacity: 100%; */
    /* height: 255px; */
    height: 14.8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: var(--color-gradient-new);
}

.main__footer p {
    position: absolute;
    margin-bottom: -9.8vw;
    color: var(--color-text);
    font-family: var(--font-family-secondary);
    font-weight: 200;
    font-size: 0.93vw;
}

.main__footer-interaction {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main__footer-interaction img{
    width: 18.5vw;
    margin: 0 0.78vw;
    
}

@media screen and (max-width: 550px) {
    .main__footer-interaction-mobile-hide {
        display: None;
    }

    .main__footer {
        /* background: var(--color-footer); */
        /* opacity: 100%; */
        /* height: 255px; */
        height: 55vw;
    }

    .main__footer p {
        /* position: absolute; */
        margin-bottom: -33vw;
        color: var(--color-text);
        font-family: var(--font-family-secondary);
        font-weight: 200;
        font-size: 3.9vw;
    }

    .main__footer-interaction img{
        width: 80vw;
        /* margin: 0 0.9vw; */
        
    }

}