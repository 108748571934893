.main__studiocontent {
    height: 80vh;
    min-width: 100vw;
    display: flex;
    flex-grow: 1;
    align-items: center;
   }
   
   .main__studiocontent-content {
       flex: 1;
       display: flex;
       justify-content: flex-end;
       align-items: center;
       flex-direction: column;
       min-height: 65vh;
   
       /* margin-right: 5rem; */
       min-width: 100vw;
       /* padding-top: 12rem; */
       /* bottom: 2rem; */
       /* position: relative; */
       /* height: 29rem; */
       /* height: 100%; */
   
       /* z-index: 1000; */
   }
   
   .main__studiocontent-content p {
       font-family: var(--font-family);
       font-weight: 400;
       font-size: 48px;
       line-height: 28px;
       color: var(--color-text);
   
       margin-top: 1.5rem;
   }
   
   .main__studiocontent__img-bg {
       background-image: url("../../data/carvlabs-logo.png"); 
       /* background-color: #000000; */
       height: 100%; /* You must set a specified height */
       background-position: center; /* Center the image */
       background-repeat: no-repeat; /* Do not repeat the image */
       background-size: cover; /* Resize the background image to cover the entire container */
       opacity: 95%;
   }