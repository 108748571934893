.main__loresection {
 height: 80vh;
 min-width: 100vw;
 display: flex;
 flex-grow: 1;
 align-items: center;
}

.main__loresection-content {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    min-height: 48vh;

    /* margin-right: 5rem; */
    min-width: 100vw;
    /* padding-top: 12rem; */
    /* bottom: 2rem; */
    /* position: relative; */
    /* height: 29rem; */
    /* height: 100%; */

    /* z-index: 1000; */
}

.main__loresection-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 48px;
    line-height: 28px;
    color: var(--color-text);

    margin-top: 1.5rem;
}

.main__loresection__img-bg__levy {
    background-image: url("../../data/levy.gif"); 
    /* background-color: #000000; */
    height: 100%; /* You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    opacity: 95%;
    width: 100%;
}

.main__loresection__back-button {
    position: absolute;
    left: 2rem,
}

.main__loresection__back-button p {
    color: var(--color-text);
}

.main__loresection__overlay{
    -webkit-animation: 8s ease 0s normal forwards 1 fadein;
    animation: 8s ease 0s normal forwards 1 fadein;
    background-color: black;
    height: 82%;
    min-width: 100vw;
    position: absolute;
    top: 128px;
    z-index: 999;
}

@keyframes fadein{
    0% { opacity:0; }
    66% { opacity:0; }
    100% { opacity:1; }
}

@-webkit-keyframes fadein{
    0% { opacity:0; }
    66% { opacity:0; }
    100% { opacity:1; }
}

@media screen and (max-width: 1250px) {
    .main__loresection__img-bg__levy {
        height: 65%;
    }

}

@media screen and (max-width: 1000px) {
    .main__loresection-content p {
        font-size: 35px;
        /* line-height: 28px; */
        

        /* margin-top: 1.5rem; */
    }

}

@media screen and (max-width: 700px) {
    .main__loresection-content {
        min-height: 39vh;
    }

    .main__loresection__overlay {
        top: 200px;
    }

    .main__loresection__img-bg__levy {
        height: 50%;
    }

}

@media screen and (max-width: 500px) {
    
    .main__loresection__img-bg__levy {
        height: 30%;
    }

    .main__loresection-content p {
        font-size: 25px;
    }

}