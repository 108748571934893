.main__firstmidsection {
    display: none;
    background: var(--color-footer);
    justify-content: center; 
    /* align-items: flex-start; */
    /* border-color: ; */
    /* border: 1vw; */
    border-top: 0.1vw solid rgba(255, 255, 255, 0.501);
    border-bottom: 0.1vw solid rgba(255, 255, 255, 0.501);
}

.main__firstmidsection__textbox {
    color: var(--color-text);
    padding: 8vw 12vw;
}

.main__firstmidsection__textbox h1 {
    /* margin-top: 20px; */
    font-family: var(--font-family);
    font-size: 3.9vw;
    /* color: var(--color-text); */
}

.main__firstmidsection__textbox p {
    padding-top: 3vw;
    font-family: var(--font-family-secondary);
    font-size: 2.6vw;
    font-weight: 200;
    /* color: var(--color-text); */
}

@media screen and (max-width: 550px) {

    .main__firstmidsection {
        display: flex;
        
    }

}