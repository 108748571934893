.main__secondsection {
    /* height: 709px; */
    height: 48vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;

}

.main__secondsection__bg {
    background-image: url("../../data/bg_3.png"); 
    /* background-color: #000000; */
    /* height: 100%; You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    /* margin-top: 2px; */
    overflow: hidden;
}

.main__secondsection__wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    /* margin-top: 23vw; */
    /* margin-top: -3vw; */
    margin-left: 35.7vw;
    /* margin-top: -111px;
    margin-left: 517px; */
}

.main__secondsection__abstract {
    display: flex;
    /* justify-content: flex-start;
    align-items: center; */
    /* flex: 1; */
    /* width: 1053px; */
    width: 64.4vw;
    /* height: 876px; */
    /* height: 60vw; */
    position: absolute;
    
    overflow: hidden;
}

.main__secondsection__img {
    display: flex;
    /* justify-content: flex-start;
    align-items: center; */
    /* flex: 1; */
    /* width: 1280px; */
    /* width: 80.0vw; */
    width: 49vw;
    /* height: 720px; */
    /* height: 49vw; */
    position: relative;
    /* margin-top: -68px; */
    margin-top: -10.1vw;
    /* margin-left: 296px; */
    margin-left: 14.5vw;
    /* overflow: hidden; */
}

.main__secondsection__textbox {
    /* display: flex; */
    /* justify-content: flex-start; */
    /* align-items: center; */
    /* flex-direction: column; */
    /* margin-left: 122px; */
    margin-top: -5.5vw;
    margin-left: 7.7vw;
    /* position: absolute; */
    width: 28vw;
    /* overflow: hidden; */
    color: var(--color-text);
}

.main__secondsection__textbox img {
    /* width: 94px; */
    height: 1.35vw;
}

.main__secondsection__textbox h1 {
    /* margin-top: 20px; */
    font-family: var(--font-family);
    font-size: 2.2vw;
}

.main__secondsection__textbox p {
    padding-top: 20px;
    font-family: var(--font-family-secondary);
    font-size: 1vw;
    font-weight: 200;
}
@keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  /* Apply floating effect on hover */
  .main__secondsection__img_float img:hover {
    animation: float 2.3s ease-in-out infinite;
  }
  .main__secondsection__img_float img {
    animation: float 2.3s ease-in-out infinite;
  }

@media screen and (max-width: 550px) {

    .main__secondsection {
        height: 86vw;
    }

    .main__secondsection__bg {
        background-position-x: 95%;
        /* background-position-y: 25%; */
    }

    .main__secondsection__abstract {
        width: 103vw;
    }

    .main__secondsection__img {
        width: 76.8vw;
        margin-top: -16.5vw;
        margin-left: 24vw;
    }

    .main__secondsection__textbox {
        display: None;
    }

    .main__secondsection__wrapper {
        margin-left: -5.5vw;
        margin-top: 4vw;
    }
  

}