* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg)
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__bg_og {
  /* ff 3.6+ */
  background: -moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

  /* opera 11.10+ */
  background: -o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

  /* ie 10+ */
  background: -ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

  /* global 92%+ browsers support */
  background: radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);
}

.gradient__bg {
   /* ff 3.6+ */
   background: -moz-radial-gradient(circle at 3% 25%, rgba(0, 31, 63, 1) 0%, rgba(4, 12, 24, 1) 100%);

   /* safari 5.1+,chrome 10+ */
   background: -webkit-radial-gradient(circle at 3% 25%, rgba(0, 31, 63, 1) 0%, rgba(4, 12, 24, 1) 100%);

   /* opera 11.10+ */
   background: -o-radial-gradient(circle at 3% 25%, rgba(0, 31, 63, 1) 0%, rgba(4, 12, 24, 1) 100%);

   /* ie 10+ */
   background: -ms-radial-gradient(circle at 3% 25%, rgba(0, 31, 63, 1) 0%, rgba(4, 12, 24, 1) 100%);

   /* global 92%+ browsers support */
   background: radial-gradient(circle at 3% 25%, rgba(0, 31, 63, 1) 0%, rgba(4, 12, 24, 1) 100%);

   /* z-index: 1000; */
}

.gradient__bg_dr {
  
    /* ff 3.6+ */
    background: -moz-radial-gradient(circle at 3% 25%, rgba(0, 31, 63, 1) 0%, rgba(50, 0, 0, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(circle at 3% 25%, rgba(0, 31, 63, 1) 0%, rgba(50, 0, 0, 1) 100%);

    /* opera 11.10+ */
    background: -o-radial-gradient(circle at 3% 25%, rgba(0, 31, 63, 1) 0%, rgba(50, 0, 0, 1) 100%);

    /* ie 10+ */
    background: -ms-radial-gradient(circle at 3% 25%, rgba(0, 31, 63, 1) 0%, rgba(50, 0, 0, 1) 100%);

    /* global 92%+ browsers support */
    background: radial-gradient(circle at 3% 25%, rgba(0, 31, 63, 1) 0%, rgba(50, 0, 0, 1) 100%);

}

.gradient__bg_teal_2 {
  
    /* ff 3.6+ */
    background: -moz-radial-gradient(circle at 3% 25%, rgba(0, 31, 63, 1) 0%, rgba(4, 82, 85, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(circle at 3% 25%, rgba(0, 31, 63, 1) 0%, rgba(4, 82, 85, 1) 100%);

    /* opera 11.10+ */
    background: -o-radial-gradient(circle at 3% 25%, rgba(0, 31, 63, 1) 0%, rgba(4, 82, 85, 1) 100%);

    /* ie 10+ */
    background: -ms-radial-gradient(circle at 3% 25%, rgba(0, 31, 63, 1) 0%, rgba(4, 82, 85, 1) 100%);

    /* global 92%+ browsers support */
    background: radial-gradient(circle at 3% 25%, rgba(0, 31, 63, 1) 0%, rgba(4, 82, 85, 1) 100%);
}


.gradient__bg_teal {
  /* ff 3.6+ */
  background: -moz-radial-gradient(circle at 3% 25%, rgba(0, 31, 63, 1) 0%, rgba(4, 82, 85, 1) 100%);

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(circle at 3% 25%, rgba(0, 31, 63, 1) 0%, rgba(4, 82, 85, 1) 100%);

  /* opera 11.10+ */
  background: -o-radial-gradient(circle at 3% 25%, rgba(0, 31, 63, 1) 0%, rgba(4, 82, 85, 1) 100%);

  /* ie 10+ */
  background: -ms-radial-gradient(circle at 3% 25%, rgba(0, 31, 63, 1) 0%, rgba(4, 82, 85, 1) 100%);

  /* global 92%+ browsers support */
  background: radial-gradient(circle at 3% 25%, rgba(0, 31, 63, 1) 0%, rgba(4, 82, 85, 1) 100%);
}


.gradient__bg_green {
  /* ff 3.6+ */
  background: -moz-radial-gradient(circle at 3% 25%, rgba(0, 31, 63, 1) 0%, rgba(4, 95, 24, 1) 100%);

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(circle at 3% 25%, rgba(0, 31, 63, 1) 0%, rgba(4, 95, 24, 1) 100%);

  /* opera 11.10+ */
  background: -o-radial-gradient(circle at 3% 25%, rgba(0, 31, 63, 1) 0%, rgba(4, 95, 24, 1) 100%);

  /* ie 10+ */
  background: -ms-radial-gradient(circle at 3% 25%, rgba(0, 31, 63, 1) 0%, rgba(4, 95, 24, 1) 100%);

  /* global 92%+ browsers support */
  background: radial-gradient(circle at 3% 25%, rgba(0, 31, 63, 1) 0%, rgba(4, 95, 24, 1) 100%);
}

.gradient_bg_black_fade {
    background: linear-gradient(90deg, #000000 50%, #00000000 102.67%);
    z-index: 1001;
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.video__bg {
  position: fixed;
  right: 0;
  /* top: 4rem; */
  min-width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
}

/* .video__bg {
  height: 300px;
  width: 300px;
  position: relative;
  object-fit: cover;
} */

.video__bg video {
width: 100%;
height: 100%;
position: absolute;
object-fit: cover;
z-index:-1;
}

.section__padding {
  padding: 4rem 8.9rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }
}
