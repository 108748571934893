iframe {
    flex-grow: 1;
    /* display: flex; */
    width: 640px;
    /* align-items: center; */
}

.main__popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* height: 100%; */
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    align-items: center; /* Align items to the start of the container */
    /* align-items: center; */
    padding-top: 5vh; /* Add some padding at the top */

    z-index: 1000;
    /*overflow-y: auto; /* Make inner content scrollable */
    overflow: visible; /* Prevent scrolling on the popup background */
    /* justify-content: space-between; */
    /* display: flex; */
    flex-direction: column;
    flex: 1;
  }
  
  .main__popup-inner-playersignup {
    margin-top: 0px; 
    /* flex: 1; */
    /* flex-direction: row; */
    position: relative;
    /* padding: 0.5rem; */
    /* width: 100%; */
    min-height: 84vh;
    /*max-width: 80vw;
    /*max-height: 80vh; /* Adjust max height to be less than the viewport height */
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    /* box-sizing: border-box; Include padding in the box model calculation */
    display: flex;
    /* flex-direction: colum; */
    /* flex: 1; */
    

  }

  .main__popup-inner-newslettersignup {
    margin-top: 0; /* Ensure there's a margin at the top of the popup content */
    /* flex: 1; */
    /* flex-direction: row; */
    position: relative;
    /* padding: 0.5rem; */
    /* width: 100%; */
    /* min-height: 84vh; */
    /*max-width: 80vw;
    /*max-height: 80vh; /* Adjust max height to be less than the viewport height */
    background-color: #0000006c;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    /* box-sizing: border-box; Include padding in the box model calculation */
    display: flex;
    /* flex-direction: row; */
    /* flex: 1; */
    

  }
  
  /* .main__popup button {
    position: absolute;
    bottom: 25vh;
    border: none;
    background: #f00;
    color: #fff;
    border-radius: 10%;
    width: 250px;
    height: 30px;
    cursor: pointer;
    opacity: 90%;
    z-index: 1050;
  } */

  .main__popup button {
    /* flex: 0.63; */
    width: 640px;
    min-height: 45px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-buttons-bg-primary);
    border: 2px solid var(--color-buttons-bg-primary);;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;
    opacity: 90%;
    /* flex:1; */
    /* text-align: center; */

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    /* margin: 1rem 0 0 0; */

    /* flex-direction: row;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex: 1; */
    
}

  /* .close-btn-newsletter {
    position: absolute;
    margin-top: 38vh;
    right: 20.25vw;
    margin-left: 638px;
    border: none;
    background: #f00;
    color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    opacity: 90%;
    z-index: 1050;
  } */

  @media screen and (max-width: 700px) {
    
    iframe,
    .main__popup button {
        width: 92vw;
    }

  }